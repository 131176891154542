import dynamic from 'next/dynamic'
import { type ComponentType, type FC, memo } from 'react'

import { useGetExhibitionPaymentMethodsQuery } from '@redux/features/homePage/api'
import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'

import type { NextApplicationsNamesType } from '@Types/applications/nextApps'
import type { ExtendBlocksType } from '@Types/blocks/blocks'
import type { PaymentMethodType, PaymentMethodsBlockType } from '@Types/blocks/paymentMethods'
import { ApplicationNames } from '@constants/applications/applicationNames'
import getAppConfig from '@services/config/config'

import { Container } from './styles'

const BasicComponent = dynamic(() => import('./basic'))
const AnimatedComponent = dynamic(() => import('./animated'))

const { publicRuntimeConfig } = getAppConfig()

type Props = {
  data: ExtendBlocksType
}

export type PaymentMethodsComponentProps = {
  paymentMethods?: PaymentMethodType[]
}

// TODO We need to come up with a solution to determine the right component for the version
const paymentMethodsComponent: Record<NextApplicationsNamesType, ComponentType<PaymentMethodsComponentProps>> = {
  [ApplicationNames.HST]: BasicComponent,
  [ApplicationNames.RN_CLASSIC]: AnimatedComponent,
  [ApplicationNames.RN_INTERIM]: AnimatedComponent,
  [ApplicationNames.RN_MODERN]: AnimatedComponent,
  [ApplicationNames.RN_NEW]: AnimatedComponent,
}

const PaymentMethodsBlock: FC<Props> = ({ data }) => {
  const { payment_methods } = data as PaymentMethodsBlockType
  const { data: paymentMethods } = useGetExhibitionPaymentMethodsQuery(payment_methods)

  const Component = paymentMethodsComponent[publicRuntimeConfig.site as NextApplicationsNamesType]

  return (
    <Container data-block={BlocksKeys.PAYMENT_METHODS}>
      <Component paymentMethods={paymentMethods} />
    </Container>
  )
}

export default memo(PaymentMethodsBlock)
